// WorkInProgress.js

import React from 'react';
import { useSpring, animated } from 'react-spring';
import './WorkInProgress.css'; // Import the CSS file for styling

const WorkInProgress = () => {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  return (
    <div className="work-in-progress-container">
         <h1>Cleanser way</h1>
      <h1>Work in Progress</h1>
      <animated.p style={fadeIn}>This page is currently under construction...</animated.p>
    </div>
  );
};

export default WorkInProgress;
