import logo from './logo.svg';
import './App.css';
import WorkInProgress from './component/WorkingProgress';

function App() {
  return (
    <div className="App">
      <WorkInProgress />
    </div>
  );
}

export default App;
